export enum DataSourceType {
  CSV = 'csv',
  SNOWFLAKE = 'snowflake',
  GOOGLE_SPREADSHEET = 'gsheet',
  GOOGLE_BIGQUERY = 'google_bigquery',
  DREMIO = 'dremio',
  EXASOL = 'exasol',
  AMAZON_REDSHIFT = 'redshift',
  AZURE_SYNAPSE = 'azure_synapse',
  EXCEL_365 = 'excel365',
  MORE_CONNECTIONS_TEMPLATE = 'data-source',
  POSTGRESQL = 'postgres',
  DATABRICKS = 'databricks',
  CLICKHOUSE = 'clickhouse',
  EMPTY = 'empty',
}

export enum DemoEnum {
  DEMO = 'demo',
}
export type DataSourceFiltersFrontend = DataSourceType | DemoEnum;

export enum ConnectionType {
  SNOWFLAKE = 'Snowflake',
  GOOGLE_SPREADSHEET = 'Google Sheets',
  GOOGLE_BIGQUERY = 'Google BigQuery',
  DREMIO = 'Dremio',
  AMAZON_REDSHIFT = 'Amazon Redshift',
  POSTGRESQL = 'PostgreSQL',
  DATABRICKS = 'Databricks',
  CLICKHOUSE = ' ClickHouse',
  EXASOL = 'Exasol (coming soon)',
  AZURE_SYNAPSE = 'Azure Synapse (coming soon)',
  EXCEL_365 = 'Excel 365 (coming soon)',
  MORE_CONNECTIONS_TEMPLATE = 'More connections soon',
  EMPTY = 'Without data',
}

export enum SnowflakePlatform {
  AWS = 'AWS',
  AZURE = 'Azure',
  GCP = 'Google Cloud Platform',
}

export enum SnowflakeAccountType {
  ACCOUNT_NAME = 'Account Name',
  ACCOUNT_LOCATOR = 'Account Locator',
}
